//todo: responsive design
//todo: shared link style
//TODO: list style type more exciting shape instead of bullets

$sans-serif: 'Nanum Gothic', sans-serif;
$serif: 'Playfair Display', serif;
$background: white;

@mixin desktop {
  @media screen and (min-width: 601px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}


// background
.shared-background {
  background-color: $background;
}

//TODO: sam hoffman above nav on home page for mobile
//home styles
.home {
  position: absolute; //most important part
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
  margin: 0 auto;
  }
    h1 {
    font-family: $serif;
    }
    ul{
    list-style-type: none;
    align-items: center;
    justify-content: center;
      li {
      list-style-type: none;
      text-align: center;
      @include desktop {
      display: inline-block;
      }
      padding: 10px 15px;
      font-family: $sans-serif;
        a {
        color:grey;
        text-decoration: none;
          &:hover {
          text-decoration: underline;
          }
      }
    }
  }
}



//general (posts, about) styles
.general-nav {
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
  }
  a {
  	font-family: $sans-serif;
    padding: 10px 15px;
    text-decoration: none;
    display: flex;
    align-items: flex-start;
    color: grey;
    &:hover {
      text-decoration: underline;
    }
  }
}

.general-title {
	font-family: $serif;
	margin: 0 auto;
  text-align: center;
  @include mobile {
   font-size: 1.75em;
  }
}


//about text style
.about-text {
 @include mobile {
 width: 80%
 }
 @include desktop {
 width: 50%;
 }
 margin: 0 auto;
 font-family: $sans-serif;
 p {
   display: inline-block;
 }
 a {
   font-family: $sans-serif;
   text-decoration: none;
   color: grey;
   &:hover {
      text-decoration: underline;
    }
  }
  ul {
    margin: 3px;
    li {
    margin: 5px;
    }
  }
}

//blog styles
.blog-listing {
  @include mobile {
  width: 80%;
  }
  @include desktop {
  width: 50%;
  }
  margin: 0 auto;
  sub {
  font-family: $sans-serif;
  margin-bottom: 0px;
  }
  h3 {
   font-family: $serif;
   margin-bottom: 0px;
   margin-top: 0px;
   display: block;
   text-align: centered;
   padding: 0px;
  }
  a {
    font-family: $serif;
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
  margin-top: 0px;
  font-family: $sans-serif;
  display: inline-block;
  }
}

.blog-post-text {
  @include mobile {
  width: 80%;
  }
  @include desktop {
  width: 50%
  }
  margin: 0 auto;
  font-family: $sans-serif;
  h1 {
  font-family: $serif;
  }
  @include desktop {
    width: 50%;
  }
  sub {
  color: grey;
  text-align: center;
  margin: auto;
  }
  a {
  color:grey;
  text-decoration: none;
  &:hover {
  text-decoration: underline;
  }
 }
}

//image style
.image-centered {
img {
  margin: 0 auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
p {
  text-align: centered;
  color: grey;
  font-size: 12px;
  font: $sans-serif;
  margin: 1 auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
 }
}

.image-large {
img {
  margin: 0 auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
p {
  text-align: centered;
  color: grey;
  font-size: 12px;
  font: $sans-serif;
  margin: 1 auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
 }
}
